import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Press from "../components/press"
import radio01 from "../media/2020-10-22-Prilog-RadioOK.mp3"

const IndexPage = () => (
  <Layout>
    <SEO title="Dogradnja luke Baška u medijima" />
    <main id="main">
      <h1>Dogradnja luke Baška u medijima</h1>
      <h2>
        Medijske objave sa završne konferencije projekta "Dogradnja luke Baška“,
        11.05.2023.
      </h2>
      <h3>Tiskani mediji i portali</h3>
      <Press
        datum="11.05.2023."
        medij="Novi list"
        naslov="U Baški otvorena nova riva. Butković: ‘Više od 300 milijuna eura ulaže se u razvoj luka na Jadranu’"
        link="https://www.novilist.hr/rijeka-regija/foto-u-baski-otvorena-nova-riva-butkovic-vise-od-300-milijuna-eura-ulaze-se-u-razvoj-luka-na-jadranu/"
      />
      <Press
        datum="11.05.2023."
        medij="Ri portal"
        naslov="Najstariji ribar Antun Rode (90) danas otvorio novu rivu u Baški"
        link="https://riportal.net.hr/rijeka/najstariji-ribar-antun-rode-90-danas-otvorio-novu-rivu-u-baski/389350/?fbclid=IwAR3n83U2IuGt6eq7t58e4zeQIE8zb2x0GBQ2rlO1Y8UZzRrfnIRu1XuMmCo"
      />
      <Press
        datum="11.05.2023."
        medij="bodulija.net"
        naslov="Završen 5,4 milijuna eura vrijedan projekt: Svečano otvorena dograđena luka Baška"
        link="https://bodulija.net/foto-zavrsen-54-milijuna-eura-vrijedan-projekt-svecano-otvorena-dogradena-luka-baska/"
      />
      <Press
        datum="11.05.2023."
        medij="otok-krk.org"
        naslov="Završen 5,4 milijuna eura vrijedan projekt luke Baška i otvorena dograđena luka"
        link="https://otok-krk.org/krk/zavrsen-5-4-milijuna-eura-vrijedan-projekt-luke-baska-i-otvorena-dogradena-luka"
      />
      <Press
        datum="11.05.2023."
        medij="burin.hr"
        naslov="Završen 5,4 milijuna eura vrijedan projekt luke Baška i otvorena dograđena luka"
        link="https://burin.hr/zavrsen-54-milijuna-eura-vrijedan-projekt-luke-baska-i-otvorena-dogradena-luka/"
      />
      <Press
        datum="12.05.2023."
        medij="morski.hr"
        naslov="Službeno zaključeni radovi na EU projektu “Dogradnja luke Baška”"
        link="https://www.morski.hr/sluzbeno-zakljuceni-radovi-na-eu-projektu-dogradnja-luke-baska/"
      />
      <Press
        datum="12.05.2023."
        medij="tockanai.hr"
        naslov="Završen 5,4 milijuna eura vrijedan projekt dogradnje luke Baška na Krku"
        link="https://tockanai.hr/biznis/zavrsen-54-milijuna-eura-vrijedan-projekt-dogradnje-luke-baska-67847/"
      />
      <h3>Video objave</h3>
      <Press
        datum="11.05.2023."
        medij="HRT"
        naslov="Regionalni dnevnik 1. program"
        link="https://vijesti.hrt.hr/gospodarstvo/u-dogradnju-luke-baska-ulozeno-54-milijuna-eura-10769572?fbclid=IwAR30N752K0c290vDbYOK6tKVOYQGecKw2DzaRdNPKLY_3OEpkOOjZsXn-SQ"
      />
      <Press
        datum="11.05.2023."
        medij="KanalRi"
        naslov="Dnevnik"
        link="https://kanal-ri.hr/90-godisnji-ribar-antun-rode-otvorio-novu-rivu-u-baski/"
      />
      <h3>Audio objave</h3>
      <Press
        datum="12.05.2023."
        medij="HRT - Radio Rijeka"
        naslov="Sigurna luka: dogradnja luke Baška"
        link="https://radio.hrt.hr/radio-rijeka/vijesti/dogradnja-luke-baska-10768817"
      />
      <h2>Medijske objave s 2. tiskovne konferencije u Baški, 29.06.2022.</h2>
      <h3>Tiskani mediji i portali</h3>
      <Press
        datum="29.06.2022."
        medij="Novi list"
        naslov="Evo što je sve do sada napravljeno na dogradnji luke Baška"
        link="https://www.novilist.hr/more/evo-sto-je-sve-do-sada-napravljeno-na-dogradnji-luke-baska/"
      />
      <Press
        datum="30.06.2022."
        medij="otok-krk.org"
        naslov="Što je sve do sada napravljeno na dogradnji luke Baška"
        link="https://otok-krk.org/krk/sto-je-sve-do-sada-napravljeno-na-dogradnji-luke-baska"
      />
      <Press
        datum="30.06.2022."
        medij="morski.hr"
        naslov="Do kraja godine trebali bi biti gotovi radovi na dogradnji luke Baška"
        link="https://morski.hr/2022/06/30/do-kraja-godine-trebali-bi-biti-gotovi-radovi-na-dogradnji-luke-baska/"
      />
      <Press
        datum="30.06.2022."
        medij="bodulija.net"
        naslov="U galeriji Zvonimir predstavljen projekt dogradnje luke Baška"
        link="https://bodulija.net/u-galeriji-zvonimir-predstavljen-projekt-dogradnje-luke-baska/"
      />
      <Press
        datum="30.06.2022."
        medij="Ri portal"
        naslov="Ovako će izgledati luka Baška; Završetak projekta najavljen za kraj 2022."
        link="https://riportal.net.hr/rijeka/ovako-ce-izgledati-luka-baska-zavrsetak-projekta-najavljen-za-kraj-2022/325028/"
      />
      <Press
        datum="30.06.2022."
        medij="Burin.hr"
        naslov="Predstavljen tijek radova u dogradnji luke Baška, završetak projekta najavljen za kraj ove godine"
        link="https://burin.hr/predstavljen-tijek-radova-u-dogradnji-luke-baska-zavrsetak-projekta-najavljen-za-kraj-ove-godine/"
      />
      <h3>Audio objave</h3>
      <Press
        datum="30.06.2022."
        medij="Hrvatski Radio - Radio Rijeka"
        naslov="Luka Baška napokon sigurnija"
        link="https://radio.hrt.hr/radio-rijeka/vijesti/baska-to-be-8227326"
      />
      <h2>
        Medijske objave s početne tiskovne konferencije u Baški, 22.10.2020.
      </h2>
      <h3>Tiskani mediji i portali</h3>

      <Press
        datum="23.10.2020."
        medij="Novi list"
        naslov="Ulaganja u baščansku luku “teška” koliko i dva godišnja proračuna"
        link="https://www.novilist.hr/rijeka-regija/otoci/ulaganja-u-bascansku-luku-teska-koliko-i-dva-godisnja-proracuna/"
      />
      <Press
        datum="23.10.2020."
        medij="La voce del popolo"
        naslov="Un giorno storico per Besca"
        link="https://lavoce.hr/cronaca/cronaca-fiumana/un-giorno-storico-per-besca?fbclid=IwAR3hmnfCM0JeC3SOaKASwpXJSCkFMcBVXQBro9_8pQcSALjuNCnaoghfbkE"
      />
      <Press
        datum="23.10.2020."
        medij="Glas Istre"
        naslov="Počinje projekt dogradnje luke Baška vrijedan 41,1 milijun kuna"
        link="https://www.glasistre.hr/gospodarstvo/pocinje-projekt-dogradnje-luke-baska-vrijedan-411-milijun-kuna-674756"
      />
      <Press
        datum="23.10.2020."
        medij="otok-krk.org"
        naslov="Ulaganja u bašćansku luku 'teška' koliko i dva godišnja proračuna"
        link="https://otok-krk.org/krk/ulaganja-u-bascansku-luku-teska-koliko-i-dva-godisnja-proracuna"
      />
      <Press
        datum="23.10.2020."
        medij="bodulija.net"
        naslov="Potpisivanjem ugovora započela 42 milijuna kuna vrijedna dogradnja luke Baška"
        link="https://bodulija.net/potpisivanjem-ugovora-zapocela-42-milijuna-kuna-vrijedna-dogradnja-luke-baska/?fbclid=IwAR0TYAYttEQC8oj90JAtLNR8st2YzN2fGWEruONp6PJbucSJL7mrE7alaNc"
      />
      <Press
        datum="22.10.2020."
        medij="seebiz.eu"
        naslov="Počinje projekt dogradnje luke Baška vrijedan 41,1 milijun kuna"
        link="https://seebiz.eu/tvrtke/pocinje-projekt-dogradnje-luke-baska-vrijedan-41-1-milijun-kuna/243496"
      />
      <Press
        datum="23.10.2020."
        medij="RiPortal"
        naslov="Župan Komadina na potpisivanju Ugovora o dogradnji luke Baška"
        link="https://riportal.net.hr/zupan-komadina-na-potpisivanju-ugovora-o-dogradnji-luke-baska/"
      />
      <Press
        datum="21.10.2020."
        medij="morski.hr"
        naslov="DOGRADNJA LUKE BAŠKA NA KRKU Sutra potpisivanje ugovora, stiže i Butković"
        link="https://morski.hr/2020/10/21/dogradnja-luke-baska-na-krku-sutra-potpisivanje-ugovora-stize-i-butkovic/"
      />
      <Press
        datum="23.10.2020."
        medij="morski.hr"
        naslov="Na Krku najavljen projekt dogradnje luke Baška, vrijedan 41 milijun kuna"
        link="https://morski.hr/2020/10/23/na-krku-najavljen-projekt-dogradnje-luke-baska-vrijedan-41-milijun-kuna/"
      />
      <Press
        datum="21.10.2020."
        medij="glasotoka.hr"
        naslov="Potpisivanje ugovora kojima započinju radovi na projektu dogradnje luke Baška"
        link="https://glasotoka.hr/2020/10/21/potpisivanje-ugovora-kojima-zapocinju-radovi-na-projektu-dogradnje-luke-baska/"
      />
      <Press
        datum="23.10.2020."
        medij="glasotoka.hr"
        naslov="Potpisani ugovori na projektu ‘Dogradnja luke Baška’"
        link="https://glasotoka.hr/2020/10/23/potpisani-ugovori-na-projektu-dogradnja-luke-baska/"
      />
      <Press
        datum="22.10.2020."
        medij="fiuman.hr"
        naslov="U dogradnju baške luke ulaže se 42 milijuna kuna"
        link="https://www.fiuman.hr/u-dogradnju-baske-luke-ulaze-se-42-milijuna-kuna/"
      />
      <h3>Video objave</h3>
      <Press
        datum="22.10.2020."
        medij="Kanal Ri"
        naslov="Dnevnik"
        link="https://www.youtube.com/watch?v=pmOD253x4uQ"
      />
      <Press
        datum="22.10.2020."
        medij="Kanal Ri"
        naslov="Prilog - Potpisan ugovor o rekonstrukciji i dogradnji luke Baška"
        link="https://www.youtube.com/watch?v=AXoriVhGOnI&feature=emb_logo"
      />
      <h3>Audio objave</h3>
      <Press
        datum="22.10.2020."
        medij="Hrvatski Radio - Radio Rijeka"
        naslov="Potpisan ugovor o obnovi bašćanske luke 'težak' više od 40 milijuna kuna"
        link="https://radio.hrt.hr/radio-rijeka/clanak/potpisan-ugovor-o-obnovi-bascanske-luke-tezak-vise-od-40-milijuna-kuna/247868/"
      />
      <Press
        datum="22.10.2020."
        medij="Radio OK"
        naslov="Radijska emisija"
        link={radio01}
      />
      <hr />
      <h2>Medijske objave s potpisivanja ugovora o financiranju 8.9.2019.</h2>
      <h3>Tiskani mediji i portali</h3>

      <Press
        datum="10.9.2019."
        medij="Večernji list"
        naslov="Potpisani ugovori za dogradnju luke Baška"
        link="https://lokalni.vecernji.hr/zupanije/potpisani-ugovori-za-dogradnju-luke-baska-16327"
      />
      <Press
        datum="10.9.2019."
        medij="morski.hr"
        naslov="Potpisan ugovor o dogradnji luke u Baškoj vrijedan 41,1 milijun kuna"
        link="https://morski.hr/2019/09/10/potpisan-ugovor-o-dogradnji-luke-u-baskoj-vrijedan-411-milijun-kuna/"
      />
      <Press
        datum="09.09.2019."
        medij="Novi list"
        naslov="Za dogradnju luke Baška 41,2 milijuna kuna: Megaprojekt za perjanicu hrvatskog turizma"
        link="http://www.novilist.hr/Vijesti/Regija/Otoci/Za-dogradnju-luke-Baska-41-2-milijuna-kuna-Megaprojekt-za-perjanicu-hrvatskog-turizma"
      />
      <Press
        datum="09.09.2019."
        medij="otok-krk.org"
        naslov="Potpisani ugovori za dogradnju luke Baška"
        link="https://otok-krk.org/krk/potpisani-ugovori-za-dogradnju-luke-baška"
      />
      <Press
        datum="09.09.2019."
        medij="privredni.hr"
        naslov="Za dogradnju luke Baška 41,2 milijuna kuna"
        link="https://privredni.hr/za-dogradnju-luke-baska-41-2-milijuna-kuna"
      />
      <Press
        datum="09.09.2019."
        medij="index.hr"
        naslov="Potpisan ugovor za dogradnju luke u Baškoj vrijedan više od 40 milijuna kuna"
        link="https://www.index.hr/vijesti/clanak/potpisan-ugovor-za-dogradnju-luke-u-baskoj-vrijedan-vise-od-40-milijuna-kuna/2114905.aspx"
      />
      <Press
        datum="24.02.2019."
        medij="N1"
        naslov="Butković na Krku o sufinanciranju dogradnje luke Baška EU sredstvima"
        link="http://hr.n1info.com/Vijesti/a373075/Butkovic-na-Krku-o-sufinanciranju-dogradnje-luke-Baska-EU-sredstvima.html"
      />
      <h3>Video objave</h3>
      <Press
        datum="11.09.2019."
        medij="Kanal Ri"
        naslov="Luka Baška"
        link="https://www.youtube.com/watch?v=LR1n2WJTIpc"
      />
      <Press
        datum="09.09.2019."
        medij="Nova TV"
        naslov="Vijesti TVN - Dogradnja luke Baška"
        link="https://www.youtube.com/watch?v=uOpjgZ5Syyw"
      />
      <p></p>
    </main>
  </Layout>
)

export default IndexPage
