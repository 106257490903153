import React from "react"
// import { Link } from "gatsby"
import PropTypes from "prop-types"

const Press = ({medij,datum,link, naslov}) => {

    return (
        <article>
            <p>{datum} <strong>{medij}</strong> | <a href={link} target="_self" rel="noopener noreferrer">{naslov}</a></p>
        </article>
    )
}

Press.propTypes = {
    datum: PropTypes.instanceOf(Date),
    naslov: PropTypes.string,
    medij: PropTypes.string,
    link: PropTypes.string,
  }
  
  Press.defaultProps = {
    datum: `01-01-2020`,
    naslov: '',
    medij: '',
    link: '',
  }


export default Press